var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRentalContract)?_c('v-row',{class:[
    _vm.currentForm.styles.align == 'center'
      ? 'justify-center'
      : _vm.currentForm.styles.align == 'left'
      ? 'justify-start'
      : 'justify-end' ],attrs:{"dense":""}},[_c('v-col',{staticClass:"hero-header",style:({
      fontFamily: _vm.font,
      backgroundColor: _vm.backgroundColor,
      color: '#ffffff',
    }),attrs:{"cols":_vm.width.cols,"sm":_vm.width.sm,"md":_vm.width.md,"lg":_vm.width.lg,"xl":_vm.width.xl}},[_c('div',{staticClass:"ml-2 mt-1"},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-home-outline")]),_c('h4',{staticClass:"mb-0"},[_vm._v("Vuokrakohde")])],1),_c('p',{staticClass:"small-text",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(_vm.currentRentalContract.apartment.address)),(_vm.currentRentalContract.apartment.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(_vm.currentRentalContract.apartment.apartmentNumber))]):_vm._e()]),_c('p',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm.currentRentalContract.apartment.zipCode)+", "+_vm._s(_vm.currentRentalContract.apartment.city)+" ")])]),_c('div',{staticClass:"ml-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-account")]),_c('h4',{staticClass:"mb-0"},[_vm._v("Vuokralaiset")])],1),_c('p',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm.currentRentalContract.tenants)+" ")])]),_c('div',{staticClass:"ml-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-account-key")]),_c('h4',{staticClass:"mb-0"},[_vm._v("Vuokranantaja")])],1),_c('p',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.getLandLords()))])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }