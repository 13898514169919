import moment from "moment";

export const getFormStatuses = (item) => {
  let hasForms = false;
  let hasActiveForms = false;
  let now = new Date(moment().format("YYYY-MM-DD"));

  if (item.forms && item.forms.length > 0) {
    // Set hasForms true
    hasForms = true;

    for (const el of item.forms) {
      if (el.alwaysActive && el.form.active) hasActiveForms = true;

      if (hasActiveForms) {
        break;
      }
      // If time range
      let startDate = el.dates.startDate;
      let endDate = el.dates.endDate;

      if (startDate && endDate) {
        if (
          moment(now).isSameOrBefore(el.dates.endDate) &&
          moment(now).isSameOrAfter(startDate) &&
          el.form.active
        ) {
          hasActiveForms = true;
        }
      } else if (startDate && !endDate && el.form.active) {
        if (moment(now).isSameOrAfter(startDate)) {
          hasActiveForms = true;
        }
      }
    }
  }

  return { hasForms, hasActiveForms };
};

export const formIsActive = (currentForm, resource) => {
  // Resource is apartment or rentalContract etc

  if (currentForm && resource) {
    // Check current form status first
    if (!currentForm.active) {
      return false;
    }

    let form = null;
    const now = moment().format("YYYY-MM-DD");

    // Select form
    for (const el of resource.forms) {
      if (String(el.form) == String(currentForm._id)) {
        form = el;
        break;
      }
    }

    if (form) {
      if (form.alwaysActive) {
        return true;
      } else {
        const startDate = form.dates.startDate;
        const endDate = form.dates.endDate;

        if (startDate && endDate) {
          const start = moment(startDate).format("YYYY-MM-DD");
          const end = moment(endDate).format("YYYY-MM-DD");
          return moment(now).isSameOrAfter(start) && moment(now).isSameOrBefore(end) ? true : false;
        } else if (startDate && !endDate) {
          const start = moment(startDate).format("YYYY-MM-DD");
          return moment(now).isSameOrAfter(start) ? true : false;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const createAndValidateTemplateJs = (JSONText) => {
  try {
    const js = JSON.parse(JSONText);
    const validKeys = ["rows", "styles", "rules"];
    const keys = Object.keys(js);

    if (keys.length !== 3) {
      throw new Error("Objektin tulee sisältää tasan kolme avainta: 'rows', 'styles' ja 'rules.");
    }

    for (const key of keys) {
      if (!validKeys.includes(key)) {
        throw new Error(
          `Virheellinen avain: '${key}'. Sallitut avaimet: 'rows', 'styles' ja 'rules'.`
        );
      }
    }

    const rows = js.rows;

    if (!Array.isArray(rows) || rows.length < 1) {
      throw new Error("'rows' avaimen tulee olla array ja sen pituuden tulee olla vähintään yksi.");
    }

    for (const row of rows) {
      if (!row.cols || !Array.isArray(row.cols) || row.cols.length < 1 || !("showRow" in row)) {
        throw new Error(
          "Jokaisen 'rows' alkion tulee sisältää 'cols' ja 'showRow' niminen avain. cols tulee olla array ja sen pituuden tulee olla vähintään yksi."
        );
      }
    }

    // Create
    return js;
  } catch (err) {
    throw new Error(err);
  }
};
