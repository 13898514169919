<template>
  <div class="w-full background">
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan lomaketta..."
      class="full-page-loader"
    ></full-page-loader>

    <!-- Form -->
    <v-container v-if="isActive && !loading" fluid>
      <!-- Header -->
      <rental-contract-header></rental-contract-header>
      <!-- Form -->
      <form-body class="pt-3 pb-3" :isPreview="false" attachTo="rentalContract"></form-body>
    </v-container>

    <div v-if="!isActive && !loading">
      <v-card align="center"
        ><h2 class="pa-2">Lomake ei ole tällä hetkellä täytettävissä.</h2></v-card
      >
    </div>
  </div>
</template>

<script>
import FormBody from "./FormBody.vue";
import RentalContractHeader from "./RentalContractHeader.vue";
import mixins from "@/mixins/mixins";
import FullPageLoader from "@/components/FullPageLoader";
import { mapActions, mapMutations, mapState } from "vuex";
import { loadFont } from "../../utils/helpers";
import { formIsActive } from "@/utils/formHelpers";

export default {
  mixins: [mixins],
  title: "Lomake",

  components: {
    FormBody,
    RentalContractHeader,
    FullPageLoader,
  },

  created() {
    this.setLoading(true);
    this.getRentalContractForm({
      formId: this.$route.params.formId,
      contractId: this.$route.params.contractId,
    });
  },

  mounted() {
    this.$nextTick(() => {
      loadFont(this.currentForm?.styles?.fontFamily);
    });
  },

  computed: {
    ...mapState("form", ["loading", "currentForm"]),

    isActive() {
      const currentForm = this.$store.state.form.currentForm;
      const contract = this.$store.state.form.currentRentalContract;
      return formIsActive(currentForm, contract);
    },
  },

  methods: {
    ...mapActions("form", ["getRentalContractForm"]),
    ...mapMutations("form", ["setLoading"]),
  },

  watch: {
    loading(val) {
      if (!val && this.currentForm?.styles?.fontFamily) {
        loadFont(this.currentForm?.styles?.fontFamily);
      }
    },
  },
};
</script>

<style scoped>
.v-application .background {
  background-color: white !important;
}
.w-full {
  min-height: 100vh;
}
</style>
