<template>
  <v-row
    v-if="currentRentalContract"
    dense
    :class="[
      currentForm.styles.align == 'center'
        ? 'justify-center'
        : currentForm.styles.align == 'left'
        ? 'justify-start'
        : 'justify-end',
    ]"
  >
    <!-- Info -->
    <v-col
      :cols="width.cols"
      :sm="width.sm"
      :md="width.md"
      :lg="width.lg"
      :xl="width.xl"
      :style="{
        fontFamily: font,
        backgroundColor: backgroundColor,
        color: '#ffffff',
      }"
      class="hero-header"
    >
      <!-- Address -->
      <div class="ml-2 mt-1">
        <div style="display: flex">
          <v-icon color="white" small class="mr-1">mdi-home-outline</v-icon>
          <h4 class="mb-0">Vuokrakohde</h4>
        </div>
        <p style="margin-bottom: 0px" class="small-text">
          {{ currentRentalContract.apartment.address
          }}<span v-if="currentRentalContract.apartment.apartmentNumber"
            >, {{ currentRentalContract.apartment.apartmentNumber }}</span
          >
        </p>
        <p class="small-text">
          {{ currentRentalContract.apartment.zipCode }}, {{ currentRentalContract.apartment.city }}
        </p>
      </div>

      <!-- Tenant -->
      <div class="ml-2">
        <div style="display: flex">
          <v-icon color="white" small class="mr-1">mdi-account</v-icon>
          <h4 class="mb-0">Vuokralaiset</h4>
        </div>
        <p class="small-text">
          {{ currentRentalContract.tenants }}
        </p>
      </div>

      <!-- Landlords -->
      <div class="ml-2">
        <div style="display: flex">
          <v-icon color="white" small class="mr-1">mdi-account-key</v-icon>
          <h4 class="mb-0">Vuokranantaja</h4>
        </div>
        <p class="small-text">{{ getLandLords() }}</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("form", ["currentRentalContract", "currentForm"]),

    width() {
      const w = this.$store.state.form.currentForm.styles.width.split(" ");
      return {
        cols: w[0],
        sm: w[1],
        md: w[2],
        lg: w[3],
        xl: w[4],
      };
    },

    backgroundColor() {
      return this.$store.state.form.currentForm.styles.colors.fieldColor;
    },

    font() {
      return this.$store.state.form.currentForm.styles.fontFamily;
    },
  },

  watch: {
    windowSize() {
      if (this.windowSize < 900) {
        this.imgContainerHeight = 300;
      } else {
        this.imgContainerHeight = 500;
      }
    },
  },

  methods: {
    getLandLords() {
      return this.currentRentalContract.landlords.join(", ");
    },
  },
};
</script>

<style scoped src="../../assets/css/apartmentStyles.css"></style>
